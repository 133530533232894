<template>
    <b-card :class="'text-white ' + (value != 999 ? 'bg-teal' : 'bg-danger')" body-class="cardBody" style="border: none; margin-bottom: 0;">
        <span style="font-weight: 700;" v-html="sensorValue"></span><br>
        {{ name }}
    </b-card>

</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: "SensorMonitor",

        props: [
            'id',
            'name',
            'value'
        ],

        computed: {
            ...mapGetters({
                getParameters: 'virtualDevice/parameters',
            }),

            parameters() {
                return this.getParameters(this.$route.params.id);
            },

            unit() {
                if (this.parameters.p_186) {
                    return this.parameters.p_186.value;
                } else return 0;
            },

            sensorValue() {
                if ((this.parameters.p_173 && this.parameters.p_173.value == this.id) || (this.parameters.p_174 && this.parameters.p_174.value == this.id)
                    || this.parameters.p_175 && this.parameters.p_175.value == this.id) {
                    return this.value == 0.0 ? 'OFF' : 'ON';
                } else if (this.value == 999) return 'ERROR';
                else return this.unit == 1 ? ((this.value * 1.8) + 32).toFixed(1) + " &#8457;" : this.value.toFixed(1) + " &#8451;";
            }
        }
    }
</script>

<style scoped>
    .cardBody {
        padding: 0.8rem 0.8rem;
    }
</style>
