<template>
    <b-card class="modules-container mb-0" style="width: 100%;">
        <h3 class="text-center">
            {{ $t('deviceInfo.module') }} {{ $t('deviceInfo.heatPump') }}
        </h3>

        <b-img v-if="relayValue" center src="/img/modules/heatpump-on.svg" alt="onoff-on" class="my-4" style="width: 15em;"/>
        <b-img v-else center src="/img/modules/heatpump-off.svg" alt="heatpump-off" class="my-4" style="width: 15em;"/>

        <parameter :mode="'info'" :param-key="operationParameterKey"
                   :param-value="{value: parameters[operationParameterKey].value, type: 'offOnSchedule'}"/>
    </b-card>
</template>

<script>
    import {mapGetters} from 'vuex';
    import {getOperationParameterKey} from "@/utilities/parameter.utility";
    import Parameter from "@/components/virtual-device/edit/parameter/v3/Parameter";

    export default {
        name: "HeatPumpModule",

        components: {
            Parameter
        },

        data() {
            return {
                relayName: "R2"
            }
        },

        computed: {
            ...mapGetters({
                getDeviceData: 'virtualDevice/deviceData',
                getParameters: 'virtualDevice/parameters',
            }),

            deviceData() {
                return this.getDeviceData(this.$route.params.id);
            },

            parameters() {
                return this.getParameters(this.$route.params.id);
            },

            operationParameterKey() {
                return getOperationParameterKey('', this.parameters);
            },

            relayValue() {
                if (this.parameters.p_1.value == 6) {
                    return this.deviceData['r_1'];
                } else if (this.parameters.p_1.value == 7) {
                    return this.deviceData['r_2'];
                }
            }
        }
    }
</script>

<style scoped>

</style>