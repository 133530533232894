<template>
    <b-card class="modules-container mb-0" style="width: 100%;">
        <h3 class="text-center">
            {{ $t('deviceInfo.module') }} {{ moduleNumber }}
        </h3>

        <b-img v-if="sensorValue" center src="/img/modules/flow-switch-on.svg" alt="flow-switch-on" class="my-4" style="width: 5em;"/>
        <b-img v-else center src="/img/modules/flow-switch-off.svg" alt="flow-switch-off" class="my-4" style="width: 5em;"/>

        <p class="text-center">Flow switch</p>
        <p class="text-center">Input: Sensor {{ inputSensor }}</p>
    </b-card>
</template>

<script>
    import {mapGetters} from 'vuex';
    import {getOperationParameterKey} from "@/utilities/parameter.utility";

    export default {
        name: "FlowSwitchModule",

        props: [
            'moduleNumber'
        ],

        data() {
            return {}
        },

        computed: {
            ...mapGetters({
                getDeviceData: 'virtualDevice/deviceData',
                getParameters: 'virtualDevice/parameters',
            }),

            deviceData() {
                return this.getDeviceData(this.$route.params.id);
            },

            parameters() {
                return this.getParameters(this.$route.params.id);
            },

            operationParameterKey() {
                return getOperationParameterKey(this.moduleNumber, this.parameters);
            },

            sensorValue() {
                let selectedSensor;

                if (this.moduleNumber == 1) {
                    selectedSensor = this.parameters.p_173.value;
                } else if (this.moduleNumber == 2) {
                    selectedSensor = this.parameters.p_174.value;
                } else if (this.moduleNumber == 3) {
                    selectedSensor = this.parameters.p_175.value;
                }

                return this.deviceData['sens_' + (parseInt(selectedSensor) + 1)];
            },

            inputSensor() {
                let selectedSensor;

                if (this.moduleNumber == 1) {
                    selectedSensor = this.parameters.p_173.value;
                } else if (this.moduleNumber == 2) {
                    selectedSensor = this.parameters.p_174.value;
                } else if (this.moduleNumber == 3) {
                    selectedSensor = this.parameters.p_175.value;
                }

                return parseInt(selectedSensor) + 1;
            }
        }
    }
</script>

<style scoped>

</style>