<template>
    <b-card class="modules-container mb-0" style="width: 100%;">
        <h3 class="text-center">
            {{ $t('deviceInfo.currentPower') }}
        </h3>

        <b-row class="mt-4" align-h="center">
            <b-col cols="auto">
                <div style='width: 200px; height: 150px;'>
                    <ejs-circulargauge ref="circulargauge" width='100%' height="200px" background="transparent">
                        <e-axes>
                            <e-axis :radius="'100%'" :startAngle='-90' :endAngle='90' :minimum="min" :maximum="max" :labelStyle='labelStyle'
                                    :minorTicks='ticks'
                                    :majorTicks='ticks' :lineStyle="{color: 'lightgrey', width: '20'}" :annotations='annotations'>
                                <e-pointers>
                                    <e-pointer type='RangeBar' :radius="'113%'" :color="'#dc3545'" :markerHeight='10' :markerWidth='10'
                                               :value="5" :animation="animation"></e-pointer>
                                </e-pointers>
                            </e-axis>
                        </e-axes>
                    </ejs-circulargauge>
                </div>
            </b-col>
        </b-row>

    </b-card>
</template>

<script>
    import Vue from 'vue';
    import {mapGetters} from 'vuex';
    import {CircularGaugePlugin, Annotations} from "@syncfusion/ej2-vue-circulargauge";
    import Parameter from "@/components/virtual-device/edit/parameter/v3/Parameter";

    Vue.use(CircularGaugePlugin);

    export default Vue.extend({
        name: "CurrentPowerModule",

        provide: {
            circulargauge: [Annotations]
        },

        components: {
            Parameter,
        },

        props: [],

        data() {
            return {
                value: 10,
                min: 0,
                max: 200,
                labelStyle: {format: 'placeholder_for_empty'},
                ticks: {width: 0},
                annotations: [{
                    content: "<div id='pointer-annotation' style='min-width: 2.5em; padding-bottom: 1.5em; line-height: 2em; text-align: center;" +
                        " width: 5em;display:table-cell;vertical-align:middle;'>" + this.getAnnotationContent(0) + "</div>",
                    angle: 180, zIndex: '1',
                    radius: '0%'
                }],
                animation: {
                    enable: true,
                    duration: 900
                },
            }
        },

        computed: {
            ...mapGetters({
                getDeviceData: 'virtualDevice/deviceData',
                getParameters: 'virtualDevice/parameters',
            }),

            deviceData() {
                return this.getDeviceData(this.$route.params.id);
            },

            parameters() {
                return this.getParameters(this.$route.params.id);
            },

            power() {
                return this.calculatePower();
            },

            approximiatedMaxPowerValue() {
                let power = 0;
                let cp = this.getCP();
                if (this.$OEM === 'pleion') {
                    let coeff = 0.0167
                    let flow = this.parameters.p_49.value;

                    power = coeff * cp * flow * 20;

                    return (power / 1000).toFixed(0);
                } else {
                    let coeff = this.parameters.p_186.value ? 648 : 1167; //in fahrenheit there is an other coefficient
                    let flow = this.parameters.p_49.value / 10; //because the value is stored as [10 x m3/h]

                    power = coeff * cp * flow * 20;

                    return (power / 1000).toFixed(0);
                }
            },
        },

        watch: {
            power: function (newVal, oldVal) {
                this.value = parseFloat(this.power);
                this.setAnnotationValue(this.value);
                this.updateSlider(this.value);
            }
        },

        methods: {
            calculatePower() {
                let s1 = this.deviceData.sens_1;
                let s2 = this.deviceData.sens_2;
                let r1 = this.deviceData.r_1;
                let power = 0;

                if (r1 == 1 && s1 > s2) {
                    let coeff = this.$OEM === 'pleion' ? 0.0167 : 1167; //in fahrenheit there is an other coefficient
                    let flow = this.$OEM === 'pleion' ? this.parameters.p_49.value : this.parameters.p_49.value / 10; //because the value is stored as [10 x m3/h]
                    let cp = this.getCP();

                    if (this.deviceData.analogue_1_ref) {
                        power = (coeff * cp * flow * (s1 - s2) * this.deviceData.analogue_1) / 100;
                    } else {
                        power = coeff * cp * flow * (s1 - s2);
                    }
                }

                return (power / 1000).toFixed(1);
            },

            getCP() {
                if (this.$OEM === 'pleion') {
                    let cpArr = [4186, 3480, 3330, 3780, 3760, 3340, 3920, 3880, 3830, 3780, 3730, 3690, 3600, 3520, 3440, 3350];

                    return cpArr[this.parameters['p_153'].value];
                } else return 1;
            },

            setAnnotationValue(value) {
                document.getElementById('pointer-annotation').innerHTML = this.getAnnotationContent(value);
            },

            updateSlider(value) {
                let circularGauge = this.$refs.circulargauge.ej2Instances;
                circularGauge.setPointerValue(0, 0, value);
            },

            getAnnotationContent(value) {
                return "<p class='mb-0' style='font-size: 2.1em; height: fit-content;'>" + value + '</p><p style="font-size: 1.2em;">kW</p>';
            }
        },

        created() {
            this.max = this.approximiatedMaxPowerValue;
        },

        mounted() {
            this.$nextTick(() => {
                this.value = parseFloat(this.calculatePower());
                this.setAnnotationValue(this.value);
                this.updateSlider(this.value);
            });
        }
    })
</script>

<style scoped>
</style>