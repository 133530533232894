<template>
    <b-card class="modules-container mb-0" style="width: 100%;">
        <h3 class="text-center">
            {{ $t('deviceInfo.module') }} {{ moduleNumber }}
        </h3>

        <b-row class="mt-4" align-h="center">
            <b-col cols="auto">
                <parameter :mode="'info'" :module-number="moduleNumber" :param-key="getSetpointParameterKey"
                           :param-value="parameters[getSetpointParameterKey]"/>

                <parameter :mode="'info'" :param-key="getHeatCoolParameterKey"
                           :param-value="{value: parameters[getHeatCoolParameterKey].value, type: 'mode'}"/>

                <div class="current-temp"
                     :style="currentTemperature == 'Err' ? 'border: 2px solid var(--danger); background: var(--danger);' : 'border: 2px solid var(--teal); background: var(--teal);'">
                    <span style="position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); font-size: 0.9rem;">{{ currentTemperature }}</span>
                </div>
                <div style="position: absolute; left: 55%; bottom: -16%; font-size: 0.9rem;">
                    {{ $t('deviceInfo.actual') }}
                </div>
            </b-col>
        </b-row>
        <br>

        <parameter :mode="'info'" :param-key="operationParameterKey"
                   :param-value="{value: parameters[operationParameterKey].value, type: 'offOnSchedule'}"/>
    </b-card>
</template>

<script>
    import {mapGetters} from 'vuex';
    import {getOperationParameterKey} from "@/utilities/parameter.utility";
    import Parameter from "@/components/virtual-device/edit/parameter/v3/Parameter";

    export default {
        name: "ThermostatModule",

        components: {
            Parameter
        },

        props: [
            'moduleNumber'
        ],

        data() {
            return {
                tempIndicatorStyle: {
                    border: this.currentTemperature !== 'Err' ? '2px solid var(--teal)' : '2px solid var(--danger)',
                    background: this.currentTemperature !== 'Err' ? 'var(--teal)' : 'var(--danger)',
                }
            }
        },

        computed: {
            ...mapGetters({
                getDeviceData: 'virtualDevice/deviceData',
                getParameters: 'virtualDevice/parameters',
            }),

            deviceData() {
                return this.getDeviceData(this.$route.params.id);
            },

            parameters() {
                return this.getParameters(this.$route.params.id);
            },

            operationParameterKey() {
                return getOperationParameterKey(this.moduleNumber, this.parameters);
            },

            getHeatCoolParameterKey() {
                if (this.moduleNumber == 1) {
                    return 'p_102';
                } else if (this.moduleNumber == 2) {
                    return 'p_106';
                } else if (this.moduleNumber == 3) {
                    return 'p_110';
                }
            },

            getSetpointParameterKey() {
                if (this.moduleNumber == 1) {
                    return 'p_35';
                } else if (this.moduleNumber == 2) {
                    return 'p_87';
                } else if (this.moduleNumber == 3) {
                    return 'p_94';
                }
            },

            currentTemperature() {
                let selectedSensor;

                if (this.moduleNumber == 1) {
                    selectedSensor = this.parameters.p_80.value;
                } else if (this.moduleNumber == 2) {
                    selectedSensor = this.parameters.p_83.value;
                } else if (this.moduleNumber == 3) {
                    selectedSensor = this.parameters.p_86.value;
                }

                let currentTemp = this.deviceData['sens_' + (parseInt(selectedSensor) + 1)];

                if (currentTemp) {
                    if (currentTemp == 999) return 'Err';
                    else return this.parameters.p_186.value == 1 ? ((currentTemp * 1.8) + 32).toFixed(1) : currentTemp.toFixed(1);
                } else {
                    return '...'; //There are transitive states where device sensor data has not yet been sent
                }
            }
        },

        methods: {
            thermostatValueFormatter(value) {
                return value + "°C"
            }
        }
    }
</script>

<style scoped>
    .current-temp {
        display: table-cell;
        vertical-align: middle;
        position: absolute;
        left: 53%;
        bottom: -3%;
        cursor: default;
        border-radius: 5px;
        width: 34px;
        height: 34px;
        color: white
    }
</style>