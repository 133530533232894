import { render, staticRenderFns } from "./IOMonitor.vue?vue&type=template&id=05f44e12&scoped=true&"
import script from "./IOMonitor.vue?vue&type=script&lang=js&"
export * from "./IOMonitor.vue?vue&type=script&lang=js&"
import style0 from "./IOMonitor.vue?vue&type=style&index=0&id=05f44e12&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05f44e12",
  null
  
)

export default component.exports