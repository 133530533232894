<template>
    <div class="animated fadeIn mt-3 pt-3 pb-0 px-3">
        <b-row>
            <b-col order="1" order-lg="1" cols="12" :lg="hasAvailableModules ? '8' : '12'" class="pr-3 pr-md-0"
                   :class="[ hasAvailableModules ? 'pr-md-0' : 'pr-md-3' ]">
                <i-o-monitor v-if="showIOMonitor" class="mb-3"/>

                <device-history-chart class="mb-3"/>

                <energy-production-chart v-if="parameters.p_1 && parameters.p_1.value == 2" class="mb-3"/>

                <relays-active-chart class="mb-3"/>
            </b-col>

            <b-col v-if="hasAvailableModules" order="2" order-lg="2" cols="12" lg="4">
                <b-row>
                    <template v-for="module in getAvailableModules">
                        <b-col class="mb-3" cols="12" md="12" lg="12" v-if="module.value == 'variant'">
                            <variant-module/>
                        </b-col>

                        <b-col class="mb-3" cols="12" md="12" lg="12" v-if="module.value == 'solar_eclipse'">
                           <solar-eclipse-module/>
                        </b-col>

                        <b-col class="mb-3" cols="12" md="12" lg="12" v-if="module.value == 1">
                            <thermostat-module :module-number="moduleNumber(module.id)"/>
                        </b-col>

                        <b-col class="mb-3" cols="12" md="12" lg="12" v-if="module.value == 2">
                            <recycle-module :module-number="moduleNumber(module.id)"/>
                        </b-col>

                        <b-col class="mb-3" cols="12" md="12" lg="12" v-if="module.value == 3">
                            <on-off-module :module-number="moduleNumber(module.id)"/>
                        </b-col>

                        <b-col class="mb-3" cols="12" md="12" lg="12" v-if="module.value == 4">
                            <eclipse-module :module-number="moduleNumber(module.id)"/>
                        </b-col>

                        <b-col class="mb-3" cols="12" md="12" lg="12" v-if="module.value == 5">
                            <fireplace-module :module-number="moduleNumber(module.id)"/>
                        </b-col>

                        <b-col class="mb-3" cols="12" md="12" lg="12" v-if="module.value == 6">
                            <heat-exchange-module :module-number="moduleNumber(module.id)"/>
                        </b-col>

                        <b-col class="mb-3" cols="12" md="12" lg="12" v-if="module.value == 7">
                            <parallel-relay-module :module-number="moduleNumber(module.id)"/>
                        </b-col>

                        <b-col class="mb-3" cols="12" md="12" lg="12" v-if="module.value == 8">
                            <flow-switch-module :module-number="moduleNumber(module.id)"/>
                        </b-col>

                        <b-col class="mb-3" cols="12" md="12" lg="12" v-if="module.value == 'heatpump'">
                            <heat-pump-module/>
                        </b-col>

                        <b-col class="mb-3" cols="12" md="12" lg="12" v-if="module.value == 'current-power'">
                            <current-power-module/>
                        </b-col>

                        <b-col class="mb-3" cols="12" md="12" lg="12" v-if="module.value == 'energy-consumption'">
                            <energy-production-module/>
                        </b-col>
                    </template>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import store from '@/store';
    import {mapGetters} from 'vuex';
    import IOMonitor from "../components/virtual-device/info/io-monitors/IOMonitor";
    import SolarEclipseModule from "../components/virtual-device/info/io-monitors/SolarEclipseModule";
    import DeviceHistoryChart from '../components/virtual-device/info/DeviceHistoryChart';
    import RelaysActiveChart from '../components/virtual-device/info/RelaysActiveChart';
    import EnergyProductionChart from "../components/virtual-device/info/EnergyProductionChart";
    import VariantModule from "../components/virtual-device/info/modules/VariantModule";
    import ThermostatModule from "../components/virtual-device/info/modules/ThermostatModule";
    import RecycleModule from "../components/virtual-device/info/modules/RecycleModule";
    import OnOffModule from "../components/virtual-device/info/modules/OnOffModule";
    import EclipseModule from "../components/virtual-device/info/modules/EclipseModule";
    import FireplaceModule from "../components/virtual-device/info/modules/FireplaceModule";
    import HeatExchangeModule from "../components/virtual-device/info/modules/HeatExchangeModule";
    import ParallelRelayModule from "../components/virtual-device/info/modules/ParallelRelayModule";
    import FlowSwitchModule from "../components/virtual-device/info/modules/FlowSwitchModule";
    import HeatPumpModule from "../components/virtual-device/info/modules/HeatPumpModule";
    import CurrentPowerModule from "../components/virtual-device/info/modules/CurrentPowerModule";
    import EnergyProductionModule from "../components/virtual-device/info/modules/EnergyProductionModule";

    export default {
        name: "VirtualDeviceInfo",

        components: {
            DeviceHistoryChart,
            RelaysActiveChart,
            EnergyProductionChart,
            IOMonitor,
            SolarEclipseModule,
            VariantModule,
            ThermostatModule,
            RecycleModule,
            OnOffModule,
            EclipseModule,
            FireplaceModule,
            HeatExchangeModule,
            ParallelRelayModule,
            FlowSwitchModule,
            HeatPumpModule,
            CurrentPowerModule,
            EnergyProductionModule
        },

        async beforeRouteEnter(to, from, next) {
            await store.dispatch('virtualDevice/fetchInfoViewData', to.params.id);
            next();
        },

        data() {
            return {
                deviceId: this.$route.params.id
            }
        },

        computed: {
            ...mapGetters({
                getUIVersion: 'virtualDevice/uiVersion',
                getUserPrivilegeLevel: 'virtualDevice/userPrivilege',
                getDeviceData: 'virtualDevice/deviceData',
                getParameters: 'virtualDevice/parameters'
            }),

            uiVersion() {
                return this.getUIVersion(this.$route.params.id);
            },

            userPrivilegeLevel() {
                return this.getUserPrivilegeLevel(this.$route.params.id);
            },

            parameters() {
                return this.getParameters(this.deviceId);
            },

            getAvailableModules() {
                let availableModules = [];

                if (this.userPrivilegeLevel > 1) {
                    if (this.uiVersion === 'pln_v1' || this.uiVersion === 'pln_v1_5') {
                        if (this.parameters.p_1 && this.parameters.p_1.value != 1 && this.parameters.p_1.value != 11) {
                            availableModules.push({value: 'variant'});
                        }
                        if(this.parameters.p_277 && this.parameters.p_277.value != 0) availableModules.push({value: 'solar_eclipse'});
                    } else {
                        if (this.parameters.p_1 && this.parameters.p_1.value != 1 && this.parameters.p_1.value != 6) {
                            availableModules.push({value: 'variant'});
                        }
                    }

                    if (this.parameters.p_78 && this.parameters.p_78.value != 0) availableModules.push(this.parameters.p_78);
                    if (this.parameters.p_81 && this.parameters.p_81.value != 0) availableModules.push(this.parameters.p_81);
                    if (this.parameters.p_84 && this.parameters.p_84.value != 0) availableModules.push(this.parameters.p_84);

                    if (this.uiVersion === 'pln_v1' || this.uiVersion === 'pln_v1_5') {
                        if (this.parameters.p_1 && this.parameters.p_1.value === 11 || this.parameters.p_1.value === 12) {
                            availableModules.push({value: 'heatpump'});
                        }
                    } else {
                        if (this.parameters.p_1 && this.parameters.p_1.value === 6 || this.parameters.p_1.value === 7) {
                            availableModules.push({value: 'heatpump'});
                        }
                    }

                    if (this.parameters.p_1 && this.parameters.p_1.value === 2) {
                        availableModules.push({value: 'current-power'});
                        availableModules.push({value: 'energy-consumption'});
                    }
                }

                return availableModules;
            },

            hasAvailableModules() {
                return (this.uiVersion === 'tdc_v3' || this.uiVersion === 'pln_v1' || this.uiVersion === 'pln_v1_5') && this.getAvailableModules.length > 0;
            },

            showIOMonitor: function () {
                let sr = this.getDeviceData(this.$route.params.id);

                return !(sr.sens_1 === null && sr.sens_2 === null && sr.sens_3 === null && sr.sens_4 === null && sr.r_1 === null && sr.r_2 === null && sr.r_3 === null);
            }
        },

        methods: {
            moduleNumber(moduleId) {
                if (moduleId == 78) return 1;
                else if (moduleId == 81) return 2;
                else if (moduleId == 84) return 3;
            }
        }
    }
</script>

<style scoped>

</style>