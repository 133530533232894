<template>
    <b-card :class="'text-white ' + (value == 1 ? 'bg-info' : 'bg-secondary')" body-class="cardBody" style="border: none; margin-bottom: 0;">
        <span style="font-weight: 700;">{{ value == 1 ? "ON" : "OFF" }} {{ analogControllerValue }} </span><br>
        {{ name }}
    </b-card>

</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: "RelayMonitor",

        props: [
            'id',
            'name',
            'value'
        ],

        computed: {
            ...mapGetters({
                getDeviceData: 'virtualDevice/deviceData'
            }),

            analogControllerValue: function () {
                let deviceData = this.getDeviceData(this.$route.params.id);

                if (this.id === deviceData.analogue_1_ref) {
                    return "(" + deviceData.analogue_1 + "%)";
                } else return "";
            }
        }
    }
</script>

<style scoped>
    .cardBody {
        padding: 0.8rem 0.8rem;
    }
</style>
