<template>
    <b-card class="modules-container mb-0" style="width: 100%;">
        <h3 class="text-center">
          Solar Eclipse
        </h3>

<!--        <b-img v-if="relayStatus" center src="/img/modules/eclipse-on.svg" alt="eclipse-on" class="my-4" style="width: 5em;"/>-->
<!--        <b-img v-else center src="/img/modules/eclipse-off.svg" alt="eclipse-off" class="my-4" style="width: 5em;"/>-->
        <p class="text-center">{{ eclipseStatus }}</p>
        <parameter v-if="eclipseStatus!=='CALCOLANDO...'" :mode="'info'" :param-key="'p_147'"
                   :param-value="{value: parameters['p_147'].value, type: 'autoLeftRightClose'}"/>
        <p  v-if="eclipseStatus =='CALCOLANDO...'" class="text-center" style="font-style: italic">premere il pulsante indietro sul dispositivo al termine del conteggio dei passi del motore</p>
    </b-card>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Parameter from "@/components/virtual-device/edit/parameter/v3/Parameter";

    export default {
        name: "SolarEclipseModule",

        components: {
            Parameter
        },

        props: [
            'moduleNumber'
        ],

        data() {
            return {

            }
        },

        computed: {
            ...mapGetters({
                getDeviceData: 'virtualDevice/deviceData',
                getParameters: 'virtualDevice/parameters',
            }),

            deviceData() {
                return this.getDeviceData(this.$route.params.id);
            },

            parameters() {
                return this.getParameters(this.$route.params.id);
            },

          eclipseStatus() {
              let relays = (this.deviceData.r_3? '1': '0') + (this.deviceData.r_4 ? '1': '0');
              if(relays == '00') return 'CLOSED'
              if(relays == '01') return this.setStrings('right')
              if(relays == '10') return this.setStrings('left')
              if(relays == '11') return 'CALCOLANDO...'
              return
            }
        },
      methods: {
        setStrings(position) {
          let val = parseInt(this.parameters?.p_278?.value);
          if (position == 'right') {
            if(val == 0) return 'SUD'
            if(val == 1) return 'NORD'
            if(val == 2) return 'EST'
            if(val == 3) return 'OVEST'
          } else if (position == 'left') {
            if(val == 0) return 'NORD'
            if(val == 1) return 'SUD'
            if(val == 2) return 'OVEST'
            if(val == 3) return 'EST'
          }
        },
      }
    }
</script>

<style scoped>

</style>