<template>
    <b-card class="modules-container mb-0" style="width: 100%;">
        <h3 class="text-center">
            {{ $t('deviceInfo.module') }} {{ moduleNumber }}
        </h3>

        <b-img v-if="relayValue" center src="/img/modules/heatexchange-on.svg" alt="heatexchange-on" class="my-4" style="width: 5em;"/>
        <b-img v-else center src="/img/modules/heatexchange-off.svg" alt="heatexchange-off" class="my-4" style="width: 5em;"/>

        <p class="text-center">Heat exchange</p>

        <parameter :mode="'info'" :param-key="operationParameterKey"
                   :param-value="{value: parameters[operationParameterKey].value, type: 'offOnSchedule'}"/>
    </b-card>
</template>

<script>
    import {mapGetters} from 'vuex';
    import {getOperationParameterKey} from "@/utilities/parameter.utility";
    import Parameter from "@/components/virtual-device/edit/parameter/v3/Parameter";

    export default {
        name: "HeatExchangeModule",

        components: {
            Parameter
        },

        props: [
            'moduleNumber'
        ],

        data() {
            return {}
        },

        computed: {
            ...mapGetters({
                getDeviceData: 'virtualDevice/deviceData',
                getParameters: 'virtualDevice/parameters',
            }),

            deviceData() {
                return this.getDeviceData(this.$route.params.id);
            },

            parameters() {
                return this.getParameters(this.$route.params.id);
            },

            operationParameterKey() {
                return getOperationParameterKey(this.moduleNumber, this.parameters);
            },

            relayValue() {
                let selectedRelay;

                if (this.moduleNumber == 1) {
                    selectedRelay = this.parameters.p_79.value;
                } else if (this.moduleNumber == 2) {
                    selectedRelay = this.parameters.p_82.value;
                } else if (this.moduleNumber == 3) {
                    selectedRelay = this.parameters.p_85.value;
                }

                return this.deviceData['r_' + (parseInt(selectedRelay) + 1)];
            }
        }
    }
</script>

<style scoped>

</style>