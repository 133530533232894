<template>
    <div class="modules-container" id="energyProductionChart" style="min-height:450px; height: 45vh; width: 100%;"></div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Highstock from 'highcharts/highstock'
    import patternFill from 'highcharts/modules/pattern-fill';

    patternFill(Highstock);

    export default {
        name: "EnergyProductionChart",

        data() {
            return {
                chart: Object
            }
        },

        computed: {
            ...mapGetters({
                getMacAddress: 'virtualDevice/macAddress',
                getUnlinked: 'virtualDevice/unlinked',
            }),

            isEmpty: function () {
                return this.getMacAddress(this.$route.params.id) === null;
            },

            isUnlinked: function () {
                return this.getUnlinked(this.$route.params.id);
            }
        },

        async mounted() {
            let chartData = [];

            if (!this.isEmpty || this.isUnlinked) {
                let response = await this.$axios.get('/get-energy-chart-data', {
                    params: {
                        id: this.$route.params.id
                    }
                });

                chartData = response.data;
            }

            this.chart = Highstock.stockChart('energyProductionChart', {
                chart: {
                    borderRadius: 4,
                    marginRight: 55
                },

                title: {
                    text: this.$t('deviceInfo.energyProduction'),
                },

                credits: {
                    enabled: false
                },

                navigator: {
                    enabled: false
                },

                scrollbar: {
                    enabled: false
                },

                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["printChart", "separator", "downloadJPEG", "downloadPDF", "downloadSVG", "downloadXLS"],
                        }
                    }
                },

                rangeSelector: {
                    buttons: [{
                        type: 'day',
                        count: 7,
                        text: '1w'
                    }, {
                        type: 'month',
                        count: 1,
                        text: '1m'
                    }, {
                        type: 'month',
                        count: 3,
                        text: '3m'
                    }, {
                        type: 'month',
                        count: 6,
                        text: '6m'
                    }, {
                        type: 'year',
                        count: 1,
                        text: '1y'
                    }, {
                        type: 'all',
                        text: 'All'
                    }],
                    selected: 0,
                    inputEnabled: true
                },

                legend: {
                    enabled: true,
                    align: 'center',
                    verticalAlign: 'bottom',
                    x: 0,
                    y: 0
                },

                xAxis: {
                    type: 'datetime',
                    ordinal: false,
                    minRange: 1440 * 60000, //1 day
                },

                yAxis: {
                    showLastLabel: true,
                    labels: {
                        x: 42,
                        formatter: function () {
                            if (this.value < 1000) {
                                return this.value + " Wh";
                            } else {
                                return (this.value / 1000).toFixed(1) + " kWh"
                            }
                        }
                    },
                },

                plotOptions: {
                    column: {
                        stacking: 'normal'
                    }
                },

                tooltip: {
                    split: false,
                    shared: true,
                    crosshairs: false,
                    useHTML: true,
                    borderColor: 'orange',
                    hideDelay: 300,

                    formatter: function () {
                        let grouping = this.points[0].series.currentDataGrouping;
                        let date;

                        if (grouping) {
                            date = new Date(this.points[0].key);
                            date = date.toDateString();
                            date = date.substr(3, date.length);

                            if (grouping.unitName === 'week') {
                                date = 'Week from ' + date;
                            } else if (grouping.unitName === 'month') {
                                date = 'Month from ' + date;
                            }
                        } else {
                            date = new Date(this.points[0].key);
                            date = date.toDateString();
                            date = date.substr(3, date.length);
                        }

                        let s = '<b>' + date + '</b>';
                        for (let i = 0; i < this.points.length; i++) {
                            let y;
                            let value = this.points[i].y;

                            if (value < 1000) {
                                y = value + " Wh";
                            } else {
                                y = (value / 1000).toFixed(1) + " kWh";
                            }

                            s += '<br/>' + '<span style="color:' + this.points[i].series.color + ';font-size: 1.2em;"' + '>● </span>'
                                + this.points[i].series.name + ': ' + y + this.points[i].point.extraMessage;
                        }

                        return s;
                    },
                },

                series: [{
                    id: 'energy',
                    name: 'Energy',
                    type: 'column',
                    data: chartData,
                    color: '#ff7f50',
                    dataGrouping: {
                        approximation: 'average',
                        enabled: true,
                        groupPixelWidth: 100,
                        units: [
                            ['day', [1]],
                            ['week', [1]],
                            ['month', [1, 3, 6]]]
                    },
                }]
            });


            //Fix for resizing chart properly when navigation drawer is fixed
            //Currently works only on Chrome
            this.ignoreFirstReflow = true; //first time you have entered route
            this.resizeObserver = new ResizeObserver(() => {
                if (!this.ignoreFirstReflow) {
                    this.chart.reflow()
                } else this.ignoreFirstReflow = false;
            });
            this.resizeObserver.observe(document.querySelector('#energyProductionChart'));

            if (this.isEmpty && !this.isUnlinked) {
                this.chart.showLoading('No data');
            }
        },

        beforeDestroy() {
            this.resizeObserver.disconnect();
            this.chart.destroy();
        }
    }
</script>

<style scoped>

</style>