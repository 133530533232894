<template>
    <div>
        <div class="modules-container" id="historyChartContainer" style="min-height:450px; height: 45vh; width: 100%;"></div>

        <date-picker v-if="$platform === 'web'" id="datePicker" v-model="chartRange" :lang="language" width="170" range confirm format="DD/MM/YY" @confirm="onRangeConfirm"/>
    </div>
</template>

<script>
    import i18n from "../../../locales/i18n";
    import {mapGetters} from 'vuex';
    import DatePicker from 'vue2-datepicker'
    import Highstock from 'highcharts/highstock'
    import exporting from 'highcharts/modules/exporting';
    import exportData from 'highcharts/modules/export-data';

    exporting(Highstock);
    exportData(Highstock);
    let fahrenheit = true;

    export default {
        name: "DeviceHistoryChart",

        components: {
            DatePicker
        },

        computed: {
            ...mapGetters({
                deviceData: 'virtualDevice/deviceData',
                getParameters: 'virtualDevice/parameters',
                getUnlinked: 'virtualDevice/unlinked',
                getMacAddress: 'virtualDevice/macAddress'
            }),

            isUnlinked: function () {
                return this.getUnlinked(this.$route.params.id);
            },

            isEmpty: function () {
                return this.getMacAddress(this.$route.params.id) === null;
            },

            language: function () {
                return i18n.locale;
            }
        },

        data() {
            return {
                deviceId: Number,
                chart: Object,
                timezone: Number,
                finishedGettingData: true,
                chartRange: null
            }
        },

        created() {
            if (this.getParameters(this.$route.params.id).p_186) {
                fahrenheit = this.getParameters(this.$route.params.id).p_186.value;
            } else fahrenheit = 0;
        },

        mounted() {
            this.deviceId = this.$route.params.id;
            this.initChart();
        },

        beforeDestroy() {
            this.resizeObserver.disconnect();
            this.chart.destroy();
        },

        methods: {
            async initChart() {
                let chartData = {
                    seriesData: [[], [], [], [], [], [], []],
                    navigatorData: [[], [], [], [], [], [], []],
                    range: 0
                };

                let seriesVisibility;
                let deviceData = this.deviceData(this.deviceId);

                if (deviceData['sens_1'] === null && deviceData['sens_2'] === null && deviceData['sens_3'] === null && deviceData['sens_4'] === null
                    && deviceData['r_1'] === null && deviceData['r_2'] === null && deviceData['r_3'] === null && deviceData['analogue_1'] === null) {
                    seriesVisibility = [true, true, true, true, true, true, false];
                } else {
                    seriesVisibility = [deviceData['sens_1'] !== null, deviceData['sens_2'] !== null, deviceData['sens_3'] !== null, deviceData['sens_4'] !== null,
                        deviceData['r_1'] !== null, deviceData['r_2'] !== null, deviceData['r_3'] !== null, false];
                }

                Highstock.SVGRenderer.prototype.symbols.refresh = function (x, y, w, h) {
                    let path = ['M', 19.453125, 8.816406, 'C', 19.386719, 8.410156, 19.007812, 8.132812, 18.601562, 8.195312, 'C', 18.195312, 8.261719, 17.917969, 8.644531,
                        17.980469, 9.050781, 'C', 17.980469, 9.054688, 17.984375, 9.058594, 17.984375, 9.0625, 'C', 18.742188, 13.527344, 15.738281, 17.765625, 11.269531,
                        18.523438, 'C', 6.804688, 19.28125, 2.570312, 16.277344, 1.8125, 11.808594, 'C', 1.050781, 7.34375, 4.058594, 3.109375, 8.523438, 2.351562,
                        'C', 11.269531, 1.882812, 14.066406, 2.84375, 15.945312, 4.898438, 'L', 12.648438, 5.996094, 'C', 12.257812, 6.128906, 12.046875, 6.550781, 12.175781,
                        6.941406, 'C', 12.304688, 7.332031, 12.726562, 7.542969, 13.117188, 7.410156, 'L', 17.585938, 5.921875, 'C', 17.890625, 5.820312, 18.097656,
                        5.535156, 18.097656, 5.214844, 'L', 18.097656, 0.746094, 'C', 18.097656, 0.332031, 17.761719, 0, 17.351562, 0, 'C', 16.941406, 0, 16.605469, 0.332031,
                        16.605469, 0.746094, 'L', 16.605469, 3.464844, 'C', 12.769531, -0.183594, 6.703125, -0.03125, 3.054688, 3.804688, 'C', -0.59375, 7.640625, -0.445312,
                        13.710938, 3.394531, 17.359375, 'C', 7.230469, 21.007812, 13.296875, 20.855469, 16.945312, 17.019531, 'C', 19.027344, 14.832031, 19.957031, 11.792969,
                        19.453125, 8.816406, 'Z', 'M', 19.453125, 8.816406];

                    let coeff = 14 / 20;
                    for (let i in path) {
                        if (!isNaN(path[i])) {
                            path[i] = path[i] * coeff + 4.75;
                        }
                    }

                    return path;
                };

                Highstock.setOptions({
                    colors: ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#7ad0f9', '#ff8f8f', '#eeb8ff', '#dc3545']
                });

                this.chart = Highstock.stockChart('historyChartContainer', {
                    title: {
                        text: this.$t('deviceInfo.deviceHistory')
                    },

                    time: {
                        useUTC: true,
                        timezoneOffset: 0
                    },

                    credits: {
                        enabled: false
                    },

                    exporting: {
                        buttons: {
                            contextButton: {
                                menuItems: ["printChart", "separator", "downloadJPEG", "downloadPDF", "downloadSVG", "downloadXLS"],
                            },
                            refreshButton: {
                                symbol: 'refresh',
                                symbolStrokeWidth: 1,
                                x: -24,
                                onclick: this.isEmpty ? () => {
                                } : this.refreshChart

                            }
                        }
                    },

                    plotOptions: {
                        series: {
                            connectNulls: false
                        }
                    },

                    chart: {
                        zoomType: 'x',
                        borderRadius: 4
                    },

                    rangeSelector: {
                        buttons: [{
                            type: 'hour',
                            count: 1,
                            text: '1h'
                        }, {
                            type: 'hour',
                            count: 6,
                            text: '6h'
                        }, {
                            type: 'day',
                            count: 1,
                            text: '1d'
                        }, {
                            type: 'day',
                            count: 7,
                            text: '1w'
                        }, {
                            type: 'all',
                            text: 'All'
                        }],
                        inputEnabled: false
                    },

                    navigator: {
                        margin: 20,
                        adaptToUpdatedData: false,
                        yAxis: {
                            gridLineWidth: 0,
                            startOnTick: false,
                            endOnTick: false,
                            minPadding: 0.05,
                            maxPadding: 0.05,
                            labels: {
                                enabled: false
                            },
                            title: {
                                text: null
                            },
                            tickWidth: 0
                        }
                    },

                    scrollbar: {
                        liveRedraw: false
                    },

                    legend: {
                        enabled: true,
                        align: 'center',
                        verticalAlign: 'bottom',
                        x: 0,
                        y: 0
                    },

                    xAxis: {
                        type: 'datetime',
                        allowDecimals: false,
                        ordinal: false,
                        events: {
                            afterSetExtremes: this.afterSetExtremes
                        },
                        minRange: 60000, //1 minute
                        range: chartData.range
                    },

                    yAxis: [
                        { // Temperature yAxis
                            alignTicks: false,
                            labels: {
                                format: fahrenheit ? '{value}°F' : '{value}°C',
                                style: {
                                    color: Highstock.getOptions().colors[1]
                                }
                            },
                            title: {
                                text: '',
                                style: {
                                    color: Highstock.getOptions().colors[1]
                                }
                            },
                            opposite: true,
                            showLastLabel: true,
                            offset: 0,
                            minRange: 10,
                        }, { // Relay yAxis
                            offset: 13,
                            minRange: 1,
                            gridLineWidth: 0,
                            min: 0,
                            max: 1,
                            labels: {
                                formatter: function () {
                                    return ' ';
                                },
                                min: 0,
                                max: 1,
                                style: {
                                    color: Highstock.getOptions().colors[3]
                                }
                            },
                            title: {
                                text: '',
                                style: {
                                    color: Highstock.getOptions().colors[3]
                                }
                            },
                            opposite: true
                        }, { // Pump speed yAxis
                            offset: 13,
                            minRange: 1,
                            gridLineWidth: 0,
                            min: 0,
                            max: 100,
                            labels: {
                                formatter: function () {
                                    return ' ';
                                },
                                min: 0,
                                max: 1,
                                style: {
                                    color: Highstock.getOptions().colors[7]
                                }
                            },
                            title: {
                                text: '',
                                style: {
                                    color: Highstock.getOptions().colors[7]
                                }
                            },
                            opposite: true
                        }],

                    tooltip: {
                        hideDelay: 300,
                        formatter: function () {
                            let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                            let daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                            let date = new Date(this.x);
                            date.setTime(date.getTime() + (date.getTimezoneOffset()) * 60 * 1000);

                            let day = daysOfWeek[date.getDay()];
                            let month = months[date.getMonth()];
                            let hour = date.getHours();
                            hour = hour < 10 ? "0" + hour : hour;
                            let min = date.getMinutes();
                            min = min < 10 ? "0" + min : min;
                            let secs = date.getSeconds();
                            secs = secs < 10 ? "0" + secs : secs;
                            let formatted = day + ", " + month + ' ' + date.getDate() + ', ' + hour + ":" + min + ":" + secs;

                            let s = '<b>' + formatted + '</b>';
                            let y = "";
                            let color;

                            for (let i = 0; i < this.points.length; i++) {
                                let suffix = fahrenheit ? " °F" : " °C";

                                switch (this.points[i].series.name) {
                                    case "S1":
                                        y = Math.round(this.points[i].y * 10) / 10 + suffix;
                                        color = Highstock.getOptions().colors[0];
                                        break;
                                    case "S2":
                                        y = Math.round(this.points[i].y * 10) / 10 + suffix;
                                        color = Highstock.getOptions().colors[1];
                                        break;
                                    case "S3":
                                        y = Math.round(this.points[i].y * 10) / 10 + suffix;
                                        color = Highstock.getOptions().colors[2];
                                        break;
                                    case "S4":
                                        y = Math.round(this.points[i].y * 10) / 10 + suffix;
                                        color = Highstock.getOptions().colors[3];
                                        break;
                                    case "R1":
                                        y = this.points[i].y === 1 ? "ON" : "OFF";
                                        color = Highstock.getOptions().colors[4];
                                        break;
                                    case "R2":
                                        y = this.points[i].y === 1 ? "ON" : "OFF";
                                        color = Highstock.getOptions().colors[5];
                                        break;
                                    case "R3":
                                        y = this.points[i].y === 1 ? "ON" : "OFF";
                                        color = Highstock.getOptions().colors[6];
                                        break;
                                    case "Pump speed":
                                        y = Math.round(this.points[i].y) + "%";
                                        color = Highstock.getOptions().colors[7];
                                        break;
                                }

                                s += '<br/>' + '<span style="color:' + color + ';font-size: 1.2em;"'
                                    + '>● </span>' + this.points[i].series.name + ': ' + y;
                            }

                            return s;
                        }
                    },

                    series: [
                        {
                            name: 'S1',
                            type: 'spline',
                            zIndex: 7,
                            showInNavigator: true,
                            visible: seriesVisibility[0],
                            data: chartData.seriesData[0],
                            dataGrouping: {
                                enabled: false
                            },
                            yAxis: 0
                        },
                        {
                            name: 'S2',
                            type: 'spline',
                            zIndex: 6,
                            showInNavigator: true,
                            visible: seriesVisibility[1],
                            data: chartData.seriesData[1],
                            dataGrouping: {
                                enabled: false
                            },
                            yAxis: 0
                        },
                        {
                            name: 'S3',
                            type: 'spline',
                            zIndex: 5,
                            showInNavigator: true,
                            visible: seriesVisibility[2],
                            data: chartData.seriesData[2],
                            dataGrouping: {
                                enabled: false
                            },
                            yAxis: 0
                        },
                        {
                            name: 'S4',
                            type: 'spline',
                            zIndex: 4,
                            showInNavigator: true,
                            visible: seriesVisibility[3],
                            data: chartData.seriesData[3],
                            dataGrouping: {
                                enabled: false
                            },
                            yAxis: 0
                        },
                        {
                            name: 'R1',
                            type: 'area',
                            fillOpacity: 0.4,
                            zIndex: 3,
                            showInNavigator: true,
                            visible: seriesVisibility[4],
                            data: chartData.seriesData[4],
                            dataGrouping: {
                                enabled: false
                            },
                            yAxis: 1,
                            step: true
                        },
                        {
                            name: 'R2',
                            type: 'area',
                            fillOpacity: 0.4,
                            zIndex: 2,
                            showInNavigator: true,
                            visible: seriesVisibility[5],
                            data: chartData.seriesData[5],
                            dataGrouping: {
                                enabled: false
                            },
                            yAxis: 1,
                            step: true
                        },
                        {
                            name: 'R3',
                            type: 'area',
                            fillOpacity: 0.4,
                            zIndex: 1,
                            showInNavigator: true,
                            visible: seriesVisibility[6],
                            data: chartData.seriesData[6],
                            dataGrouping: {
                                enabled: false
                            },
                            yAxis: 1,
                            step: true
                        },
                        {
                            name: 'Pump speed',
                            type: 'spline',
                            zIndex: 5,
                            showInNavigator: true,
                            visible: seriesVisibility[7],
                            data: chartData.seriesData[7],
                            dataGrouping: {
                                enabled: false
                            },
                            yAxis: 2
                        },
                    ],
                }, function (chart) {
                    let rangeSelector = document.querySelector('div.highcharts-container');
                    let datePicker = document.querySelector('#datePicker');
                    let datePickerWrapper = document.createElement("div");

                    datePicker.style.left = 'calc(100% - 231px)';
                    datePicker.style.top = '-414px';
                    datePicker.style.zIndex = 10;

                    datePickerWrapper.style.height = '0px'; //needs to be 0 so the datepicker does not push the chart downwards
                    datePickerWrapper.style.zIndex = 1;
                    datePickerWrapper.appendChild(datePicker);
                    rangeSelector.appendChild(datePickerWrapper);
                });

                this.chart.navigator.series[4].update({
                    type: 'area',
                    fillOpacity: 0.4,
                    zIndex: 3,
                    step: true
                }, false);
                this.chart.navigator.series[5].update({
                    type: 'area',
                    fillOpacity: 0.4,
                    zIndex: 2,
                    step: true
                }, false);
                this.chart.navigator.series[6].update({
                    type: 'area',
                    fillOpacity: 0.4,
                    zIndex: 1,
                    step: true
                }, false);

                if (!this.isEmpty || this.isUnlinked) {
                    this.refreshChart();
                } else {
                    this.chart.showLoading('No data');
                }

                //Fix for resizing chart properly when navigation drawer is fixed
                //Currently works only on Chrome
                this.ignoreFirstReflow = true; //first time you have entered route
                this.resizeObserver = new ResizeObserver(() => {
                    if (!this.ignoreFirstReflow) {
                        this.chart.reflow()
                    } else this.ignoreFirstReflow = false;
                });
                this.resizeObserver.observe(document.querySelector('#historyChartContainer'));
            },

            afterSetExtremes(e) {
                if ((!this.isEmpty || this.isUnlinked) && this.finishedGettingData) {
                    this.finishedGettingData = false;
                    this.chart.showLoading(this.$t('deviceInfo.chartLoadingData'));
                    this.$axios.get('/get-chart-data', {
                        params: {
                            id: this.$route.params.id,
                            start: Math.round(e.min),
                            end: Math.round(e.max)
                        },

                    }).then(response => {
                        this.chart.series[0].setData(response.data[0], false);
                        this.chart.series[1].setData(response.data[1], false);
                        this.chart.series[2].setData(response.data[2], false);
                        this.chart.series[3].setData(response.data[3], false);
                        this.chart.series[4].setData(response.data[4], false);
                        this.chart.series[5].setData(response.data[5], false);
                        this.chart.series[6].setData(response.data[6], false);
                        this.chart.series[7].setData(response.data[7]);
                        this.chart.hideLoading();
                    }).finally(() => {
                        this.finishedGettingData = true;
                    });
                }
            },

            refreshChart() {
                this.changeRange();
            },

            onRangeConfirm(range) {
                if (range[0] === null || range[1] === null) return;

                let rangeStart = Math.round(range[0].getTime() / 1000);
                let rangeEnd = Math.round(range[1].getTime() / 1000);

                this.changeRange(rangeStart, rangeEnd);
            },

            async changeRange(rangeStart, rangeEnd) {
                if (this.finishedGettingData) {
                    this.finishedGettingData = false;
                    this.chart.showLoading('Loading data from server...');

                    let response = await this.$axios.get('/get-first-time-chart-data', {
                        params: {
                            id: this.$route.params.id,
                            rangeStart: rangeStart,
                            rangeEnd: rangeEnd
                        }
                    });
                    let seriesData = response.data.seriesData;
                    let navigatorData = response.data.navigatorData;

                    this.chart.series[0].setData(seriesData[0], false);
                    this.chart.series[1].setData(seriesData[1], false);
                    this.chart.series[2].setData(seriesData[2], false);
                    this.chart.series[3].setData(seriesData[3], false);
                    this.chart.series[4].setData(seriesData[4], false);
                    this.chart.series[5].setData(seriesData[5], false);
                    this.chart.series[6].setData(seriesData[6], false);
                    this.chart.series[7].setData(seriesData[7], false);
                    this.chart.navigator.series[0].setData(navigatorData[0], false);
                    this.chart.navigator.series[1].setData(navigatorData[1], false);
                    this.chart.navigator.series[2].setData(navigatorData[2], false);
                    this.chart.navigator.series[3].setData(navigatorData[3], false);

                    //Resize navigator data for relays since we only have one y-axis for navigator
                    //find new ratio here
                    let points = seriesData[0];
                    let max = points[0][1];
                    for (let i = 0; i < points.length; i++) {
                        if (max < points[i][1]) max = points[i][1];
                    }

                    points = seriesData[1];
                    for (let i = 0; i < points.length; i++) {
                        if (max < points[i][1]) max = points[i][1];
                    }

                    points = seriesData[2];
                    for (let i = 0; i < points.length; i++) {
                        if (max < points[i][1]) max = points[i][1];
                    }

                    points = seriesData[3];
                    for (let i = 0; i < points.length; i++) {
                        if (max < points[i][1]) max = points[i][1];
                    }

                    let ratio = max * 1.8;
                    //

                    let resizedData = [[], [], []];

                    for (let i = 0; i < navigatorData[4].length; i++) {
                        resizedData[0].push([navigatorData[4][i][0], navigatorData[4][i][1] * ratio]);
                        resizedData[1].push([navigatorData[5][i][0], navigatorData[5][i][1] * ratio]);
                        resizedData[2].push([navigatorData[6][i][0], navigatorData[6][i][1] * ratio]);

                    }

                    this.chart.navigator.series[4].setData(resizedData[0], false);
                    this.chart.navigator.series[5].setData(resizedData[1], false);
                    this.chart.navigator.series[6].setData(resizedData[2], false);
                    this.chart.navigator.series[7].setData(navigatorData[7], false);

                    let end;
                    if (this.isUnlinked || true) {
                        end = new Date(response.data.endTime).valueOf() + response.data.timezone * 3600 * 1000;
                    } else {
                        end = new Date().valueOf() + response.data.timezone * 3600 * 1000;
                    }

                    this.chart.xAxis[0].setExtremes((end - response.data.range), end, false);

                    this.chart.hideLoading();
                    this.chart.redraw();
                    this.finishedGettingData = true;
                }
            }
        }
    }

</script>

<style scoped>

</style>