<template>
    <div class="modules-container" id="relaysAverageChart" style="min-height:450px; height: 45vh; width: 100%;"></div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Highstock from 'highcharts/highstock'

    export default {
        name: "RelaysAverageChart",

        data() {
            return {
                chart: Object
            }
        },

        computed: {
            ...mapGetters({
                getMacAddress: 'virtualDevice/macAddress',
                getUnlinked: 'virtualDevice/unlinked',
            }),

            isEmpty: function () {
                return this.getMacAddress(this.$route.params.id) === null;
            },

            isUnlinked: function () {
                return this.getUnlinked(this.$route.params.id);
            }
        },

        async mounted() {
            let relayData = [];

            if (!this.isEmpty || this.isUnlinked) {
                let response = await this.$axios.get('/get-relays-chart-data', {
                    params: {
                        id: this.$route.params.id
                    }
                });

                relayData = response.data.relayData;
            }

            this.chart = Highstock.stockChart('relaysAverageChart', {
                chart: {
                    borderRadius: 4,
                    marginRight: 55
                },

                title: {
                    text: this.$t('deviceInfo.relayActiveTime'),
                },

                credits: {
                    enabled: false
                },

                navigator: {
                    enabled: false
                },

                scrollbar: {
                    enabled: false
                },

                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["printChart", "separator", "downloadJPEG", "downloadPDF", "downloadSVG", "downloadXLS"],
                        }
                    }
                },

                rangeSelector: {
                    buttons: [{
                        type: 'day',
                        count: 7,
                        text: '1w'
                    }, {
                        type: 'month',
                        count: 1,
                        text: '1m'
                    }, {
                        type: 'month',
                        count: 3,
                        text: '3m'
                    }, {
                        type: 'month',
                        count: 6,
                        text: '6m'
                    }, {
                        type: 'year',
                        count: 1,
                        text: '1y'
                    }, {
                        type: 'all',
                        text: 'All'
                    }],
                    selected: 0,
                    inputEnabled: true
                },

                legend: {
                    enabled: true,
                    align: 'center',
                    verticalAlign: 'bottom',
                    x: 0,
                    y: 0
                },

                xAxis: {
                    type: 'datetime',
                    ordinal: false,
                    minRange: 1440 * 60000, //1 day
                },

                yAxis: {
                    tickAmount: 5,
                    tickInterval: 1440 / 8,
                    floor: 0,
                    ceiling: 1440,
                    min: 0,
                    max: 1440,
                    showLastLabel: true,
                    labels: {
                        x: 42,
                        formatter: function () {
                            return ((this.value / 1440) * 100).toFixed(0) + '%';
                        }
                    },
                },

                plotOptions: {
                    column: {
                        stacking: 'normal'
                    }
                },

                tooltip: {
                    split: false,
                    shared: true,
                    crosshairs: false,
                    useHTML: true,
                    borderColor: 'orange',
                    hideDelay: 300,

                    formatter: function () {
                        let grouping = this.points[0].series.currentDataGrouping;
                        let date;

                        if (grouping) {
                            date = new Date(this.points[0].key);
                            date = date.toDateString();
                            date = date.substr(3, date.length);

                            if (grouping.unitName === 'week') {
                                date = 'Week from ' + date;
                            } else if (grouping.unitName === 'month') {
                                date = 'Month from ' + date;
                            }
                        } else {
                            date = new Date(this.points[0].key);
                            date = date.toDateString();
                            date = date.substr(3, date.length);
                        }

                        let s = '<b>' + date + '</b>';

                        for (let i = 0; i < this.points.length; i++) {
                            s += '<br/>' + '<span style="color:' + this.points[i].series.color + ';font-size: 1.2em;"'
                                + '>● </span>' + this.points[i].series.name + ': ' + ((this.points[i].y / 1440) * 100).toFixed(2) + '%';
                        }

                        return s;
                    },
                },

                series: [
                    {
                        name: 'Offline',
                        type: 'column',
                        color: '#D3D3D3',
                        events: {
                            legendItemClick: function () {
                                if (this.visible) {
                                    this.chart.series[1].setVisible(false);
                                    this.chart.series[2].setVisible(false);
                                    this.chart.series[3].setVisible(false);
                                } else {
                                    this.chart.series[1].setVisible(true);
                                    this.chart.series[2].setVisible(true);
                                    this.chart.series[3].setVisible(true);
                                }
                            }
                        }
                    },
                    {
                        name: 'Offline',
                        type: 'column',
                        data: relayData[3],
                        color: '#D3D3D3',
                        stack: 'r1',
                        linkedTo: 'r1',
                        // showInLegend: false,
                        states: {
                            hover: {
                                enabled: false
                            }
                        },
                        dataGrouping: {
                            approximation: 'average',
                            enabled: true,
                            groupPixelWidth: 100,
                            units: [
                                ['day', [1]],
                                ['week', [1]],
                                ['month', [1, 3, 6]]]
                        },
                    },
                    {
                        name: 'Offline',
                        type: 'column',
                        data: relayData[3],
                        color: '#D3D3D3',
                        stack: 'r2',
                        linkedTo: 'r2',
                        enableMouseTracking: false,
                        showInLegend: false,
                        states: {
                            hover: {
                                enabled: false
                            }
                        },
                        dataGrouping: {
                            approximation: 'average',
                            enabled: true,
                            groupPixelWidth: 100,
                            units: [
                                ['day', [1]],
                                ['week', [1]],
                                ['month', [1, 3, 6]]]
                        }
                    },
                    {
                        name: 'Offline',
                        type: 'column',
                        data: relayData[3],
                        color: '#D3D3D3',
                        stack: 'r3',
                        linkedTo: 'r3',
                        enableMouseTracking: false,
                        showInLegend: false,
                        states: {
                            hover: {
                                enabled: false
                            }
                        },
                        dataGrouping: {
                            approximation: 'average',
                            enabled: true,
                            groupPixelWidth: 100,
                            units: [
                                ['day', [1]],
                                ['week', [1]],
                                ['month', [1, 3, 6]]]
                        }
                    },
                    {
                        id: 'r1',
                        name: 'R1',
                        type: 'column',
                        data: relayData[0],
                        color: '#7ad0f9',
                        stack: 'r1',
                        dataGrouping: {
                            approximation: 'average',
                            enabled: true,
                            groupPixelWidth: 100,
                            units: [
                                ['day', [1]],
                                ['week', [1]],
                                ['month', [1, 3, 6]]]
                        },
                    }, {
                        id: 'r2',
                        name: 'R2',
                        type: 'column',
                        data: relayData[1],
                        color: '#ff8f8f',
                        stack: 'r2',
                        dataGrouping: {
                            approximation: 'average',
                            enabled: true,
                            groupPixelWidth: 100,
                            units: [
                                ['day', [1]],
                                ['week', [1]],
                                ['month', [1, 3, 6]]]
                        }
                    }, {
                        id: 'r3',
                        name: 'R3',
                        type: 'column',
                        data: relayData[2],
                        color: '#eeb8ff',
                        stack: 'r3',
                        dataGrouping: {
                            approximation: 'average',
                            enabled: true,
                            groupPixelWidth: 100,
                            units: [
                                ['day', [1]],
                                ['week', [1]],
                                ['month', [1, 3, 6]]]
                        }
                    }]
            });


            //Fix for resizing chart properly when navigation drawer is fixed
            //Currently works only on Chrome
            this.ignoreFirstReflow = true; //first time you have entered route
            this.resizeObserver = new ResizeObserver(() => {
                if (!this.ignoreFirstReflow) {

                    this.chart.reflow()
                } else this.ignoreFirstReflow = false;
            });
            this.resizeObserver.observe(document.querySelector('#relaysAverageChart'));

            if (this.isEmpty && !this.isUnlinked) {
                this.chart.showLoading('No data');
            }
        },

        beforeDestroy() {
            this.resizeObserver.disconnect();
            this.chart.destroy();
        }
    }
</script>

<style scoped>

</style>