<template>
    <b-card class="modules-container mb-0" style="width: 100%;">
        <h3 class="text-center">
            {{ $t('deviceInfo.energyProduction') }}
        </h3>

        <carousel class="mt-4" :per-page="1" :paginationPadding="7" :loop="true" :autoplay="true" :autoplayTimeout="8000" :speed="750">
            <slide>
                <b-row>
                    <b-col>
                        <b-img class="float-right" src="../../img/modules/solar-energy.svg" width="80px"></b-img>
                    </b-col>
                    <b-col style="display: flex; align-items: center;">
                        <div>
                            <span class="title">{{ $t('deviceInfo.today') }}</span>
                            <br>
                            <span class="subTitle">{{ today }}</span>
                        </div>
                    </b-col>
                </b-row>
            </slide>
            <slide>
                <b-row>
                    <b-col>
                        <b-img class="float-right" src="../../img/modules/solar-energy.svg" width="80px"></b-img>
                    </b-col>
                    <b-col style="display: flex; align-items: center;">
                        <div>
                            <span class="title">{{ $t('deviceInfo.thisMonth') }}</span>
                            <br>
                            <span class="subTitle">{{ month }}</span>
                        </div>
                    </b-col>
                </b-row>
            </slide>
            <slide>
                <b-row>
                    <b-col>
                        <b-img class="float-right" src="../../img/modules/solar-energy.svg" width="80px"></b-img>
                    </b-col>
                    <b-col style="display: flex; align-items: center;">
                        <div>
                            <span class="title">{{ $t('deviceInfo.thisYear') }}</span>
                            <br>
                            <span class="subTitle">{{ year }}</span>
                        </div>
                    </b-col>
                </b-row>
            </slide>
        </carousel>
    </b-card>
</template>

<script>
    import {mapGetters} from 'vuex';
    import {getOperationParameterKey} from "@/utilities/parameter.utility";
    import Parameter from "@/components/virtual-device/edit/parameter/v3/Parameter";
    import {Carousel, Slide} from 'vue-carousel';

    export default {
        name: "EnergyProductionModule",

        components: {
            Parameter,
            Carousel,
            Slide
        },

        props: [],

        data() {
            return {
                today: 'N/A',
                month: 'N/A',
                year: 'N/A'
            }
        },

        computed: {
            ...mapGetters({
                getDeviceData: 'virtualDevice/deviceData',
                getParameters: 'virtualDevice/parameters',
            }),

            deviceData() {
                return this.getDeviceData(this.$route.params.id);
            },

            parameters() {
                return this.getParameters(this.$route.params.id);
            }
        },

        methods: {
            energyFormatter(value) {
                if (value < 1000) {
                    return value + " Wh";
                } else {
                    return (value / 1000).toFixed(1) + " kWh"
                }
            }
        },

        async beforeCreate() {
            if (!this.isEmpty || this.isUnlinked) {
                let response = await this.$axios.get('/get-widget-energy-data', {
                    params: {
                        id: this.$route.params.id
                    }
                });

                let widgetData = response.data;
                this.today = this.energyFormatter(widgetData[0]);
                this.month = this.energyFormatter(widgetData[1]);
                this.year = this.energyFormatter(widgetData[2]);
            }
        }
    }
</script>

<style scoped>
    .title {
        font-size: 1.2em;
    }

    .subTitle {
        font-size: 1.5em;
        font-weight: 600;
    }
</style>