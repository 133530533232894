<template>
    <b-card class="modules-container" body-class="cardBody">
        <b-row>
            <b-col cols="6" sm="3" lg="3" v-if="deviceData && deviceData.sens_1 !== null" class="px-2 mt-3">
                <sensor-monitor :id="0" :name="s1Name" :value="deviceData.sens_1"/>
            </b-col>

            <b-col cols="6" sm="3" lg="3" v-if="deviceData && deviceData.sens_2 !== null" class="px-2 mt-3">
                <sensor-monitor :id="1" :name="s2Name" :value="deviceData.sens_2"/>
            </b-col>

            <b-col cols="6" sm="3" lg="3" v-if="deviceData && deviceData.sens_3 !== null" class="px-2 mt-3">
                <sensor-monitor :id="2" :name="s3Name" :value="deviceData.sens_3"/>
            </b-col>

            <b-col cols="6" sm="3" lg="3" v-if="deviceData && deviceData.sens_4 !== null" class="px-2 mt-3">
                <sensor-monitor :id="3" :name="s4Name" :value="deviceData.sens_4"/>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="6" sm="3" lg="3" v-if="deviceData && deviceData.r_1 !== null" class="px-2 mt-3">
                <relay-monitor id="R1" :name="r1Name" :value="deviceData.r_1"/>
            </b-col>

            <b-col cols="6" sm="3" lg="3" v-if="deviceData && deviceData.r_2 !== null" class="px-2 mt-3">
                <relay-monitor id="R2" :name="r2Name" :value="deviceData.r_2"/>
            </b-col>

            <b-col cols="6" sm="3" lg="3" v-if="deviceData && deviceData.r_3 !== null" class="px-2 mt-3">
                <relay-monitor id="R3" :name="r3Name" :value="deviceData.r_3"/>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
    import {mapGetters} from 'vuex';
    import SensorMonitor from "./SensorMonitor";
    import RelayMonitor from "./RelayMonitor";

    export default {
        name: "IOMonitor",

        components: {
            SensorMonitor,
            RelayMonitor
        },

        data() {
            return {
                deviceId: this.$route.params.id
            }
        },

        computed: {
            ...mapGetters({
                getDeviceData: 'virtualDevice/deviceData',
                getParameters: 'virtualDevice/parameters',
                getSensorName: 'virtualDevice/sensorName',
                getRelayName: 'virtualDevice/relayName'
            }),

            deviceData() {
                return this.getDeviceData(this.deviceId);
            },

            unit() {
                return this.getParameters(this.$route.params.id).p_186.value;
            },

            s1Name() {
                return this.getSensorName({deviceId: this.deviceId, sensorId: 1});
            },

            s2Name() {
                return this.getSensorName({deviceId: this.deviceId, sensorId: 2});
            },

            s3Name() {
                return this.getSensorName({deviceId: this.deviceId, sensorId: 3});
            },

            s4Name() {
                return this.getSensorName({deviceId: this.deviceId, sensorId: 4});
            },

            r1Name() {
                return this.getRelayName({deviceId: this.deviceId, relayId: 1});
            },

            r2Name() {
                return this.getRelayName({deviceId: this.deviceId, relayId: 2});
            },

            r3Name() {
                return this.getRelayName({deviceId: this.deviceId, relayId: 3});
            }
        },
    }
</script>

<style scoped>
    .cardBody {
        padding-top: 0;
    }
</style>