<template>
    <b-card class="modules-container mb-0" style="border: none; width: 100%;">
        <h3 class="text-center">
            {{ $t('deviceInfo.variant') }}
        </h3>

        <b-img center :src="variantImageSrc" style="max-width: 30vh;"></b-img>
    </b-card>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: "VariantModule",

        computed: {
            ...mapGetters({
                parameters: 'virtualDevice/parameters'
            }),

            deviceVariant: function () {
                return this.parameters(this.$route.params.id)['p_1'].value;
            },

            variantImageSrc: function () {
                if (this.$OEM === 'pleion') {
                    if (this.deviceVariant == 12) {
                        return '/img/variants/pleion-v1/variant2-module.svg';
                    } else {
                        return '/img/variants/pleion-v1/variant' + this.deviceVariant + '-module.svg';
                    }
                } else {
                    if (this.deviceVariant == 7) {
                        return '/img/variants/v2/variant2-module.svg';
                    } else {
                        return '/img/variants/v2/variant' + this.deviceVariant + '-module.svg';
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>